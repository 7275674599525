<template>
  <div id="app">
    <img class="logo" src="https://static.yximgs.com/udata/pkg/kwystatic/logo.png" />
    <p class="appname">快影</p>
    <p class="bottom"><a href="https://beian.miit.gov.cn/#/Integrated/index">京ICP备15023266号-5</a></p>
  </div>
</template>

<script>
import axios from "@/utils/axios.js";
export default {
  created() {
    // 请求发送示例
    axios.get("testJob::/");
  }
};
</script>

<style lang="less">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  .bottom {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    font-size: 16px;
    a {
      text-decoration: none;
      color: #555;
    }
  }
  .logo {
    width: 50px;
    height: 50px;
    margin-top: 100px;
  }
  .appname {
    font-size: 32px;
  }
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
